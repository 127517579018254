<template>
  <el-drawer :visible.sync="showExampleDialog" title="成功案例" size="50%">
    <div style="padding: 0 20px" id="loading-wrap" class="example-dialog-wrap">
      <el-card class="box-card" v-for="item in exampleList" style="margin-bottom: 20px">
        <div slot="header" class="clearfix" style="display: flex;align-items: center">
          <el-avatar style="margin-right: 10px" size="medium" :src="item.avatar"></el-avatar>
          <div>
            <span style="display: block;">{{ item.nick_name }}</span>
            <span style="display: block;font-size: 10px;color: #999999">上次聊天：{{item.end_time}}</span>
          </div>
        </div>
        <div v-for="item1 in item.chats" class="chat-item" :class="item1.speakType==2?'':'chat-item-reverse'">
          <div class="chat-img" ><el-avatar size="small" :src="item1.speakType==2?item.avatar:'http://lechunbucket.oss-cn-beijing.aliyuncs.com/kboneMiniResource/public/lechun-logo.jpg'"></el-avatar></div>
          <div class="chat-miniprogram" v-if="item1.msgType=='weapp'">
            <p style="display: flex;align-items: center;font-size: 10px"><el-avatar style="margin-right: 6px" size="small" src="http://lechunbucket.oss-cn-beijing.aliyuncs.com/kboneMiniResource/public/lechun-logo.jpg"></el-avatar> 乐纯</p>
            <p>{{item1.weappTitle}}</p>
            <img style="width: 200px;height: 150px" src="http://lechunbucket.oss-cn-beijing.aliyuncs.com/kboneMiniResource/public/lechun-logo.jpg">
            <p style="border-top: 1px solid #dddddd;padding-top: 6px;font-size: 10px">小程序</p>
          </div>
          <div class="chat-content" v-else-if="item1.msgType=='text'" v-html="item1.textContent"></div>
          <div class="chat-content" v-else>[ {{item1.msgType}} ]</div>
        </div>
      </el-card>
    </div>
    <el-empty v-if="exampleList.length<=0">
      <template slot="description">
        <i v-if="exLoading" style="font-size: 40px" class="el-icon-loading"></i>
        <span v-else>暂无</span>
      </template>
    </el-empty>
  </el-drawer>
</template>

<script>
export default {
name: "exampleDialog",
  data(){
    return {
      exampleList:[],
      showExampleDialog:false,
      exLoading:true,
    }
  },
  methods:{
    getExampleList(id){
      this.exampleList = []
      this.exLoading = true
      this.showExampleDialog = true
      this.$apiGet('lechun-bi/generateScene/getSuccessCase', { materialId:id}).then(res => {
        this.exLoading = false
        this.exampleList = res

      })
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.example-dialog-wrap{
  .el-card__header{
    border-bottom: 1px solid #d7d7d7!important;
  }
  .chat-item{
    display: flex;
    margin-bottom: 20px;
    font-size: 12px;
  }

  .chat-item-reverse{
    flex-direction: row-reverse;
  }
  .chat-img{
    width: 28px;
    display: block;
    margin-right: 10px;
  }
  .chat-img img{
    width: 28px!important;
  }
  .chat-item-reverse .chat-img{
    margin-left: 10px;
    margin-right: 0;
  }
  .chat-content{
    background: #f5f5f5;
    padding: 10px;
    border-radius: 5px;
    display: inline-block;
    white-space: pre-wrap;
    max-width: 80%;
  }
  .chat-item-reverse .chat-content{
    background: #a6e860;
  }
  .chat-miniprogram{
    border: 1px solid #dddddd;
    border-radius: 5px;
    padding: 10px;
    width: 200px;
  }
}

</style>
